import * as React from 'react';
import Layout from '../components/Layout';
import Seo from '../components/seo';

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <div className="hero has-text-centered py-6">
      <h1 className="title is-1">404: Not found</h1>
      <h1 className="title is-1">404: Página no encontrada</h1>
    </div>
  </Layout>
);

export default NotFoundPage;
